<script>
    import { mapActions, mapState } from "vuex";
    import { declineOfNumber } from "@/utils";
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";
    import ThePersonalPromocodeCard from "@/ThePersonal/ThePersonalPromocodeCard";
    import ThePersonalLinkCard from "@/ThePersonal/ThePersonalLinkCard";
    import ThePersonalSkeletonBox from "@/ThePersonal/ThePersonalSkeletonBox";

    export default {
        name: "ThePersonalMain",
        components: {
            ThePersonalSkeletonBox,
            ThePersonalLinkCard,
            ThePersonalButton,
            ThePersonalPromocodeCard,
            ThePersonalTooltip,
        },
        data() {
            return {
                promoCoupons: [],
                isLoadedPromoCoupons: false,
                copiedPromoCoupon: "",
            };
        },
        computed: {
            ...mapState("Profile", ["user", "totalOrders", "totalBonuses", "totalSales"]),
            declineOrders() {
                return declineOfNumber(this.totalOrders, ["заказ", "заказа", "заказов"]);
            },
            declineSales() {
                return declineOfNumber(this.totalSales, ["акция", "акции", "акций"]);
            },
            declineBonuses() {
                return declineOfNumber(this.totalBonuses, [
                    "мэдкоин",
                    "мэдкоина",
                    "мэдкоинов",
                ]);
            },
        },
        mounted() {
            this.getProfileTotalOrders();
            if (!this.totalBonuses) {
                this.getProfileTotalBonuses();
            }
            if (!this.totalSales) {
                this.getProfileTotalSales();
            }

            this.getProfilePromoCoupons();
        },
        methods: {
            ...mapActions("Profile", [
                "getProfileTotalOrders",
                "getProfileTotalBonuses",
                "getProfileTotalSales",
            ]),
            getProfilePromoCoupons() {
                return this.$store.profileApi.getProfilePromoCoupons().then((response) => {
                    this.promoCoupons = response.splice(0, 2);
                    this.isLoadedPromoCoupons = true;
                });
            },
            goToDiscountsPage() {
                this.$router.push({ name: "ThePersonalDiscounts" });
            },
            copyCouponHandler(code) {
                this.copiedPromoCoupon = code;
            },
        },
    };
</script>

<template>
    <section class="the-personal-main">
        <h1 class="the-personal-main__title">Привет, {{ user.name }}!</h1>
        <div class="the-personal-main__inner">
            <div class="the-personal-main__col">
                <the-personal-link-card
                    title="Активные заказы"
                    type="orders"
                    class="the-personal-main__card"
                >
                    У вас {{ totalOrders }} {{ declineOrders }}
                </the-personal-link-card>
                <the-personal-link-card
                    title="Акции"
                    type="discounts"
                    route-hash="#sales"
                    class="the-personal-main__card"
                >
                    Сейчас проходит {{ totalSales }} {{ declineSales }}
                </the-personal-link-card>
                <the-personal-link-card
                    title="Бонусы"
                    type="bonuses"
                    class="the-personal-main__card"
                >
                    На счету:&nbsp;&nbsp;
                    <b>{{ totalBonuses | formatPrice }} {{ declineBonuses }}</b>
                    <div class="the-personal-main__card-icon">
                        <img src="@/ThePersonal/assets/icons/tooltip-info.svg" alt="" />
                        <div class="the-personal-main__card-tooltip">
                            <the-personal-tooltip width="220">
                                Виртуальная бонусная валюта. <br />
                                1 мэдкоин = 1₽
                            </the-personal-tooltip>
                        </div>
                    </div>
                    <div class="desktop-hidden">
                        Это виртуальная валюта Madrobots. 1 мэдкоин = 1₽
                    </div>
                </the-personal-link-card>
                <the-personal-link-card
                    title="Справочная информация"
                    type="support"
                    class="the-personal-main__card"
                >
                    Ответы на частые вопросы про заказы, доставку, оплату и не только
                </the-personal-link-card>
            </div>
            <div class="the-personal-main__col">
                <div class="the-personal-main__notification">
                    Нажми на промокод, чтобы скопировать!
                </div>
                <div class="the-personal-main__promocodes" v-show="isLoadedPromoCoupons">
                    <the-personal-promocode-card
                        v-for="coupon in promoCoupons"
                        :key="coupon.id"
                        :coupon="coupon"
                        :is-copied="coupon.code === copiedPromoCoupon"
                        class="the-personal-main__card"
                        @on-copy="copyCouponHandler"
                    ></the-personal-promocode-card>
                </div>

                <the-personal-skeleton-box
                    v-show="!isLoadedPromoCoupons"
                    :list="2"
                    type="promocode"
                    direction="column"
                ></the-personal-skeleton-box>
                <the-personal-button
                    class="the-personal-main__button"
                    @click="goToDiscountsPage"
                >
                    Смотреть все промокоды
                </the-personal-button>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    .the-personal-main__title {
        position: relative;
        z-index: 1;
        margin-bottom: 15px;
        padding-left: 15px;
        color: $personal-title-color;
        font-size: 19px;
        font-weight: 700;
        line-height: 30px;
        @include bp($bp-desktop-sm) {
            padding-left: 20px;
            font-size: 25px;
            font-weight: $personal-title-weight;
            line-height: 30px;
        }
    }

    .the-personal-main__inner {
        @include bp($bp-desktop-sm) {
            display: flex;
            margin: 0 -10px;
        }
    }

    .the-personal-main__col {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
        @include bp($bp-desktop-sm) {
            flex-basis: 50%;
            max-width: 50%;
            margin: 0 10px;
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    .the-personal-main__card {
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
        @include bp($bp-desktop-sm) {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    .the-personal-main__card-icon {
        display: none;
        @include bp($bp-desktop-sm) {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            margin-left: 3px;
            &:hover {
                .the-personal-main__card-tooltip {
                    display: block;
                }
            }
        }
    }

    .the-personal-main__card-tooltip {
        display: none;
    }

    .the-personal-main__notification {
        margin-bottom: 10px;
        padding: 8px 15px 10px;
        color: $personal-notification-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        background-color: $personal-notification-fill;
        border-radius: $personal-box-radius;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-main__button {
        margin-top: 10px;
        @include bp($bp-desktop-sm) {
            margin-top: 20px;
            max-width: 300px;
        }
    }
</style>
